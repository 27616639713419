<template>
  <CRow class="12">
    <CToaster position="top-right" :autohide="1500">
      <template v-for="(toast, index) in toasts">
        <CToast
            :key="'toast' + toast.text + index"
            :show="true"
            :header="toast.header === '' ? null : toast.header"
        >
          {{ toast.text }}
        </CToast>
      </template>
    </CToaster>
    <CCard class="col-12 col-md-6">
      <CCardHeader>
        <strong>Yönetici Liste</strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol class="col-12 mt-1">

              <CInput
                type="text"
                description="ex. AdvancerEnglish"
                label="Ad soyad"
                disabled
                v-model="adminName"
            >
                <template #prepend-content>
              <CIcon name="cil-user"/>
            </template>
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="col-12">
            <CInput
                type="text"
                label="E-Posta Adresi"
                v-model="eMail"
            >
              <template #prepend-content>
                <CIcon name="cil-user"/>
              </template>
              <template #append>
                <CButton v-if="showMailSaveButton" color="success" @click="updateMail">
                  Kaydet
                </CButton>
              </template>
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="col-12">
            <CInput
                placeholder="Yeni Şifreyi Giriniz"
                :type="passwordInputType"
                label="Hesap Şifresi"
                required
                v-model="password">
              <template #prepend-content>
                <CIcon name="cil-lock-locked"/>
              </template>
              <template #append>
                <CButton @click="passwordVisibleHandler" color="light">
                  <CIcon name="cil-low-vision"/>
                </CButton>
              </template>
            </CInput>
              <c-badge
                  class='col-12'
                  v-if='password !== "" && (password.length < 6 || password.length > 64)'
                  color="danger"
              >Şifre Minimum 6, Maksimum 64 Karakter Uzunluğunda Olmalıdır.
              </c-badge>
              <template #prepend-content>
                <CIcon name="cil-user"/>
              </template>
              <template #append>
                <CButton @click="passwordVisibleHandler" color="light"><CIcon  name="cil-low-vision"/></CButton>
              </template>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="d-flex justify-content-end">
            <CButton class="mb-4" :disabled='!validateEditAdminPassword' v-if='showPasswordSaveButton && validateEditAdminPassword' color="success" @click="updatePassword">
              Kaydet
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CRow>
</template>

<script>
import {AdministratorAdministration} from "@/services/api-service";

export default {
  name: "AdminsList",
  components: {

  },
  data () {
    return {
      passwordInputType:'',
      password: '',
      passwordFromServer:'',
      adminName:'',
      name:'',
      eMail: '',
      eMailFromServer: '',
      isDataLoading: false,
      toasts:[],
    }
  },
  mounted() {
    this.loadAdminInfos();
  },
  computed: {
    validateEditAdminPassword() {
      return (
          (this.password !== '' && (this.password.length >= 6 && this.password.length <= 64))
      )
    },
    showMailSaveButton() {
      return this.eMail !== this.eMailFromServer
    },
    showPasswordSaveButton() {
      return this.password !== this.passwordFromServer
    },
  },
  methods: {
    passwordVisibleHandler() {
      if(this.passwordInputType === "password") {
        this.passwordInputType = "text"
      } else {
        this.passwordInputType = "password"
      }
    },
    deleteAdmin() {
      this.$swal({
        title: 'Emin misiniz?',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
              'Silindi!',
              'İşlem Başarıyla Gerçekleştirildi!',
              'success'
          )
        }
      });
    },
    showToast: function (text = 'İşlem Başarıyla Gerçekleştirildi.', header = 'Bilgi') {
      this.toasts.push({text, header});
    },

    async loadAdminInfos() {
      const id = parseInt(this.$route.params['id']);
      const result = await AdministratorAdministration.listAdministrators();
      const adminInfos = result.data.administratorList.find(f => f.id === id);
      this.eMail = adminInfos.eMail;
      this.eMailFromServer = adminInfos.eMail
      this.adminName = adminInfos.name;
    },
    async updateMail() {
      const id = parseInt(this.$route.params['id']);
      await AdministratorAdministration.changeAdministratorEmail(id, this.eMail);
      this.eMailFromServer = this.eMail;
      this.$toast.success("Yönetici Mail Adresi Başarıyla Güncellendi!")
    },
    async updatePassword() {
      const id = parseInt(this.$route.params['id']);
      await AdministratorAdministration.changeAdministratorPassword(id, this.password);
      this.passwordFromServer = this.password;
      this.$toast.success("Yönetici Şifresi başarıyla Güncellendi!")
      this.password = ''
    },
  }
}

</script>

<style scoped>

</style>
